import React from "react";
import { graphql } from 'gatsby'
import loadable from "@loadable/component";
import LayoutTwo from "../components/layoutTwo";
import Seo from "../components/seo"
import { PageLinks } from "../common/site/page-static-links";
import { useTranslation } from "react-i18next"
import { useLocalization } from "gatsby-theme-i18n"
const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const TeamDetailDesc = loadable(() => import("../components/TeamDetailDesc/TeamDetailDesc"));
const Enquire = loadable(() => import("../components/Enquire/Enquire"));

const TeamDetail = ({ data }, props) => {
    const { t } = useTranslation()
    const { locale } = useLocalization()
    const PageData = data?.strapiTeam
    const themestyle = data.strapiThemeConfig?.theme_style;
    
    let breadcrumData;

    if (PageLinks?.team_parent_label) {
        breadcrumData = { parentname: t("team_parent_label"), parent: PageLinks.team_parent, subparentname: t("team_label"), subparent: PageLinks.team, pagename: PageData.title }
    } else {
        breadcrumData = { parentname: t("team_label"), parent: PageLinks.team, pagename: PageData.title }
    }

    let popsearch = "Generic pages"
    if(locale == "ar")
        popsearch = "الصفحات العامة"

    return (
        <LayoutTwo popularSearch={popsearch} footerContact={'footercontact'} enquire={'hide'}>
            <div className="layout-padding-top">
                <BreadcrumbModule {...breadcrumData} />

                <TeamDetailDesc {...PageData} themestyle={themestyle} />
            </div>

            <div className="d-md-none">
                <Enquire cta_1_label={t("email_us")} cta_2_label={t("call_us")} whatsapp={PageData?.whatsapp}/>
            </div>
        </LayoutTwo>
    )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiTeam
  return (
    <Seo title={`${PageData.title} ${PageData.designation ? ' | '+PageData.designation : ''}`} description={`Get to know about ${PageData.title} here. Contact one of our estate agents for assistance in finding the right property for you.`} />
  )
}

export default TeamDetail


export const query = graphql`
query ($locale: String!,$page_id: Int) {
    strapiTeam(strapi_id: {eq: $page_id}, locale: { eq: $locale }) {
      ...TeamFragment
        content {
          data {
            content
          }
        }
    }
    strapiThemeConfig {
      theme_style
    }
  }
`